<template>
  <div class="detail-container">
    <item-tip />
    <menu-article :id="id" :article="detail" :title="title" />
  </div>
</template>
<script>
  import { getMenuArticle } from '@/api/site'
  import { SetLogoPathNames } from '@/utils/index'
  export default {
    name: 'Contactus',
    data() {
      return {
        id: 'QW',
        title: '联系我们',
        isMultImage: false,
        showDetails: true,
        detail: {
          content: '',
          list: [],
          ArticleID: '',
          title: '',
          abstract: '',
        },
      }
    },
    async created() {
      await this.getDetail()
    },
    methods: {
      async getDetail() {
        if (this.id) {
          let param = { SID: this.id }
          var res = await getMenuArticle(param)
          if (res.data) {
            let data = res.data
            this.detail.ArticleID = data.ArticleID
            this.detail.title = data.Title || ''
            this.detail.abstract = data.Abstract || ''
            if (data.Contents) {
              this.detail.content = data.Contents
            }
            if (data.LogoPath && data.OssImagePath) {
              this.detail.list = SetLogoPathNames(data)
            }
          }
        }
      },
    },
  }
</script>
